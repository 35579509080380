export default {
  show(value) {
    const Intercom = window['Intercom'];
    if (Intercom) {
      Intercom(value);
    } else {
      window.open('mailto:support@ciis.ca', '');
    }
  },
  execute(value, properties?) {
    const Intercom = window['Intercom'];
    if (Intercom) {
      Intercom(value, properties);
    }
  }
};
